// Application Core / No Media queries for Lte-IE8 Use

//$mq-support: false;
//$mq-fixed-value: 1024px;
$is-ie: true;

@import
    "foundation_normalize",
    "foundation_settings",
    "foundation",
    "framework",
    "core",
    "core/home",
    
    "ie-specific";
